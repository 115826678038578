import { setFocusTrap } from '@vfde-brix/core';
import {
    createGlobalSearchOverlayComponent,
    IGlobalSearchOverlayBusinessLogic,
} from '@vfde-brix/global-search-overlay';
import { SearchInput } from '@vfde-brix/search-input';
import { useDispatch } from '../../../app/store';
import {
    resetSearch,
    setOverlayOpen,
} from '../slice';
import {
    getAllSiblings,
    traverseToBody,
} from '../../../helpers/domHelper';
import { CLASSNAME_BACKDROP } from '@vfde-brix/overlay';

/**
 * Global Search Overlay
 */
export const mountGlobalSearchOverlay = (
    container: HTMLElement,
    inputField: SearchInput,
    callbacks?: { onOpen?: CallableFunction; onClose?: CallableFunction },
) => {
    const dispatch = useDispatch();

    let removeFocusTrap: CallableFunction | undefined | null;

    const tryRemoveFocusTrap = () => {
        if (!removeFocusTrap) {
            return;
        }

        removeFocusTrap();
        removeFocusTrap = null;
    };

    const props: IGlobalSearchOverlayBusinessLogic = {
        onOpen: () => {
            // Set siblings and siblings of parents until body to `inert`
            // to prevent any interaction via mouse, keyboard or assistive technologies while the overlay is open.
            traverseToBody(container, element => {
                const siblings = getAllSiblings(element, [`.${CLASSNAME_BACKDROP}`]);

                for (const sibling of siblings) {
                    sibling.setAttribute('inert', 'inert');
                }
            });

            removeFocusTrap = setFocusTrap(container);
            inputField.getInputField().focus();
            callbacks?.onOpen?.();
        },
        onClose: () => {
            // update state on backdrop click
            tryRemoveFocusTrap();

            // Remove `inert` attribute again
            traverseToBody(container, element => {
                const siblings = getAllSiblings(element, [`.${CLASSNAME_BACKDROP}`]);

                for (const sibling of siblings) {
                    sibling.removeAttribute('inert');
                }
            });

            dispatch(setOverlayOpen(false));
            dispatch(resetSearch());
            callbacks?.onClose?.();
        },
        closeButton: {
            onClick: () => {
                tryRemoveFocusTrap();
                dispatch(setOverlayOpen(false));
            },
        },
    };

    return createGlobalSearchOverlayComponent(container, props);
};
