import { IInitialState } from './interface';
import createAppSlice from '../../app/createAppSlice';
import beacon from '@vfde-sails/beacon';
import { RootState } from '../../app/store';

const initialState: IInitialState = {
    contactInfo: null,
    isFlyoutOpen: false,
};

const contactSlice = createAppSlice({
    name: 'Contact',
    initialState,
    reducers: create => {
        const createAThunk = create.asyncThunk.withTypes<{
            rejectValue: string;
        }>();

        return {
            setInitialState: create.reducer<IInitialState['contactInfo']>((state, action) => {
                state.contactInfo = action.payload;
            }),
            toggleFlyout: createAThunk<void, boolean>((arg, { getState }) => {
                const isOpen = contactSlice.selectors.selectIsFlyoutOpen(getState() as RootState);

                if (!isOpen) {
                    beacon.publish('sails:flyout', {
                        type: 'TOGGLE_FLYOUT',
                        payload: {
                            focus: arg,
                            activePage: 'cf-hotline',
                        },
                    });
                }

                return;
            }),
            toggleFlyoutSuccess: create.reducer<any>((state, action) => {
                state.isFlyoutOpen = !!action.payload.activePage;
            }),
        };
    },
    selectors: {
        selectContactNumber: sliceState => sliceState.contactInfo?.phoneNumber || null,
        selectIsFlyoutOpen: sliceState => sliceState.isFlyoutOpen,
    },
});

export default contactSlice;

export const {
    setInitialState,
    toggleFlyout,
    toggleFlyoutSuccess,
} = contactSlice.actions;

export const {
    selectContactNumber,
    selectIsFlyoutOpen,
} = contactSlice.selectors;
