import { isKeyboardTriggeredClickEvent } from '@vfde-sails/utils';
import {
    getChildrenByTagName,
    getNextSiblingByTagName,
    getPreviousSiblingByTagName,
} from '../../../helpers/domHelper';
import {
    isDesktopViewport,
    isMobileViewport,
    isTouchInput,
} from '@vfde-brix/core';
import { HOVER_DELAY } from '../../../constants';
import { DOMEvent } from 'app/interface';
import {
    getFromElement,
    getRelatedPosition,
    getToElement,
} from './mddAnimationHelper';
import { MouseEnterOrMouseLeave } from '../interface';
import {
    NAV_ITEM_LEVEL_1_CLASSNAME,
    NAV_ITEM_LEVEL_2_CLASSNAME,
} from '../constants';

/**
 * Loop over given nav items and initialize them
 * @param listItems all nav items that can potentially have submenus (1st and 2nd level currently)
 * @param hideSubLevelOnMobile whether first level items should act as links even when having submenus on mobile viewports
 */
const initNavigation = (
    listItems: HTMLLIElement[],
    hideSubLevelOnMobile = false,
): CallableFunction => {
    const init = () => {
        for (const listItem of listItems) {
            initNavItem(listItem, hideSubLevelOnMobile);
        }
    };

    init();

    return () => {
        init();
    };
};

/**
 * Returns all level 1 and level 2 nav items from DOM
 */
export const getNavItems = () => Array.from(document.querySelectorAll<HTMLLIElement>(`.${NAV_ITEM_LEVEL_1_CLASSNAME}, .${NAV_ITEM_LEVEL_2_CLASSNAME}`));

/**
 * Handles opening and closing of menus via mouse, keyboard and touch devices
 * by controlling aria attributes
 * @param navItem a single nav item that can potentially have submenus
 * @param hideSubLevelOnMobile whether first level items should act as links even when having submenus on mobile viewports
 */
const initNavItem = (navItem: HTMLLIElement, hideSubLevelOnMobile = false) => {
    const submenu = getChildrenByTagName<HTMLUListElement>(navItem, 'ul')[0];

    // When a nav item has no submenu, it doesn't need special treatment, we can continue to next nav item
    if (!submenu) {
        return;
    }

    const triggers = getTriggers(navItem);
    const [link, button] = triggers;

    // If neither link nor button are present for nav items with submenu, we have no valid DOM structure for navigation and can leave
    if (!link && !button) {
        return;
    }

    // Remove event listeners for nav item (added by previous viewport)
    navItem.removeEventListener('focusout', focusOutHandler);
    navItem.removeEventListener('keydown', handleKeyDownListener);
    const navEvents = ['nav:mouseenter', 'nav:mouseleave'] as const;
    const events = ['mouseenter', 'mouseleave'] as const;
    navEvents.forEach(type => navItem.removeEventListener(type, handleNavMouseEvent));
    events.forEach(type => navItem.removeEventListener(type, handleMouseEvent));
    link?.removeEventListener('click', handleLinkClickEvent);
    button?.removeEventListener('click', handleButtonClickEvent);

    // When we don't want to show sublevels on mobile viewport, we can continue to next nav item
    if (hideSubLevelOnMobile && !isDesktopViewport()) {
        return;
    }

    // We set default aria attributes for all triggers we found
    [link, button].forEach(trigger => initTrigger(trigger, submenu));

    // Make sure everything is closed initially
    resetSubmenu(triggers);

    // On focus out, we need to close the submenu
    navItem.addEventListener('focusout', focusOutHandler);

    // Close the submenu on escape key press
    navItem.addEventListener('keydown', handleKeyDownListener);

    // If there is a link, mouse events control the aria state in desktop viewport
    if (link) {
        addCustomMouseEvents(navItem);

        navEvents.forEach(type => {
            isDesktopViewport() && navItem.addEventListener(type, handleNavMouseEvent);
        });

        // For touch (in desktop) & keyboard the link has to act as toggle button on first click
        link.addEventListener('click', handleLinkClickEvent);
    }

    // If there is a button, click events control aria state
    if (button) {
        // If there is an additional link, we can add a label to the button
        link && button.setAttribute('aria-label', link.textContent!.trim());
        button.addEventListener('click', handleButtonClickEvent);
    }
};

/**
 * Toggles a sub menu item
 */
export const toggleSubmenu = (trigger: HTMLButtonElement | HTMLAnchorElement | undefined, force?: boolean): void => {
    if (!trigger) {
        return;
    }

    if (
        (isMobileViewport() && trigger.tagName.toLowerCase() === 'a')
        || (isDesktopViewport() && trigger.tagName.toLowerCase() === 'button')
    ) {
        trigger.removeAttribute('aria-expanded');
        trigger.removeAttribute('aria-controls');

        return;
    }

    const state = force !== undefined ? force : !isSubmenuOpen(trigger);
    trigger.setAttribute('aria-expanded', state.toString());
};

/**
 * Get submenu state from aria attribute
 */
export const isSubmenuOpen = (trigger: HTMLButtonElement | HTMLAnchorElement): boolean => trigger?.getAttribute('aria-expanded') === 'true';

/**
 * Initialize all aria needed attributes with default state
 */
export const initTrigger = (trigger: HTMLAnchorElement | HTMLButtonElement, submenu: HTMLElement) => {
    if (!trigger) {
        return;
    }

    if (
        (isMobileViewport() && trigger.tagName.toLowerCase() === 'a')
        || (isDesktopViewport() && trigger.tagName.toLowerCase() === 'button')
    ) {
        trigger.removeAttribute('aria-expanded');
        trigger.removeAttribute('aria-controls');

        return;
    }

    trigger.setAttribute('aria-expanded', 'false');

    submenu.id && trigger.setAttribute('aria-controls', `${submenu.id}`);
};

/**
 * Close submenu
 */
const resetSubmenu = (triggers: (HTMLButtonElement | HTMLAnchorElement)[]): void => {
    for (const trigger of triggers) {
        toggleSubmenu(trigger, false);
    }
};

/**
 * Close all submenus
 */
export const resetAllSubmenus = (navItems: HTMLLIElement[]): void => {
    for (const navItem of navItems) {
        if (!navItemHasSubmenu(navItem)) {
            // if the item has no submenu, we don't need to reset it
            continue;
        }

        const triggers = getTriggers(navItem);

        for (const trigger of triggers) {
            toggleSubmenu(trigger, false);
        }
    }
};

/**
 * Checks if the given navItem has a submenu
 */
export const navItemHasSubmenu = (navItem: HTMLLIElement) => !!getChildrenByTagName<HTMLUListElement>(navItem, 'ul')[0];

/**
 * Get triggers for a nav item (direct button|a descendants)
 */
const getTriggers = (listItem: HTMLLIElement): [HTMLAnchorElement, HTMLButtonElement] => {
    // Get trigger items for submenu
    const link = getChildrenByTagName<HTMLAnchorElement>(listItem, 'a')[0];
    const button = getChildrenByTagName<HTMLButtonElement>(listItem, 'button')[0];

    return [
        link,
        button,
    ];
};

/**
 * Bind mouse events and forward them into a custom event with a little delay (which are then handled by animation and toggling)
 * This prevents the navigation from opening when the user just goes over the elements on his way to somewhere else
 */
const addCustomMouseEvents = (listItem: HTMLLIElement) => {
    // TODO: This could be refactored to use pointerevents instead of mouseevents, but those report "pen" instead of "mouse" in Chrome (at least within the VM)
    // TODO: also: pointerevents somehow manage to destroy animations in level 3

    const events = ['mouseenter', 'mouseleave'] as const;
    events.forEach(type => {
        isDesktopViewport() && listItem.addEventListener(type, handleMouseEvent);
    });
};

const handleKeyDownListener = (event: KeyboardEvent) => {
    const currentTarget = event.currentTarget as HTMLLIElement;
    const link = getChildrenByTagName(currentTarget, 'a')[0] as HTMLAnchorElement;
    const button = getChildrenByTagName(currentTarget, 'button')[0] as HTMLButtonElement;

    if (event.key === 'Escape' && (isSubmenuOpen(link) || isSubmenuOpen(button))) {
        event.stopImmediatePropagation();
        resetSubmenu(getTriggers(currentTarget));
        // focus the link inside the list element
        link?.focus(); // optional chaining, because this function is also used in footer and there we don't have an <a> in the <li>
    }
};

const handleButtonClickEvent = (event: DOMEvent<MouseEvent, HTMLButtonElement>) => {
    event.stopPropagation();

    const { currentTarget } = event;
    const submenu = getChildrenByTagName<HTMLUListElement>(currentTarget.parentElement!, 'ul')[0];
    const isOpen = () => isSubmenuOpen(currentTarget);
    const link = getPreviousSiblingByTagName<HTMLAnchorElement>(currentTarget, 'a')
        || getNextSiblingByTagName<HTMLAnchorElement>(currentTarget, 'a');

    if (submenu) {
        // need to read the level from submenus classname and append it to the name
        // otherwise level 3 will inherit the custom property from level 2
        const level = submenu.className.match(/--(level-\d)/);
        handleSubmenuMaxHeightTransitionEnd(`--mc-submenu-height${level ? `-${level[1]}` : ''}`, submenu, isOpen);
    }

    // the timeout is needed to trigger the transition correctly when closing the submenu
    setTimeout(() => {
        const open = isOpen();
        toggleSubmenu(link, !open);
        toggleSubmenu(currentTarget, !open);
    }, 50);
};

const handleLinkClickEvent = (event: MouseEvent) => {
    const currentTarget = event.currentTarget as HTMLAnchorElement;
    const isTouch = isTouchInput();
    const isDesktop = isDesktopViewport();

    if (isDesktop && !isSubmenuOpen(currentTarget) && (isKeyboardTriggeredClickEvent(event) || isTouch)) {
        event.preventDefault();
        event.stopPropagation();

        const button = getPreviousSiblingByTagName<HTMLButtonElement>(currentTarget, 'button')
            || getNextSiblingByTagName<HTMLButtonElement>(currentTarget, 'button');

        toggleSubmenu(currentTarget, true);
        toggleSubmenu(button, true);
    }
};

const focusOutHandler = (event: DOMEvent<FocusEvent, HTMLLIElement>) => {
    if (!isDesktopViewport()) {
        return;
    }

    const currentTarget = event.currentTarget;

    if (!currentTarget.matches(':focus-within')) {
        resetSubmenu(getTriggers(currentTarget));
    }
};

const handleNavMouseEvent = (event: CustomEvent) => {
    event.stopPropagation();
    const currentTarget = event.currentTarget;
    toggleSubmenu(getChildrenByTagName(currentTarget as HTMLElement, 'a')[0] as HTMLAnchorElement, event.type === 'nav:mouseenter');
    toggleSubmenu(getChildrenByTagName(currentTarget as HTMLElement, 'button')[0] as HTMLButtonElement, event.type === 'nav:mouseenter');
};

// TODO: we should clear this variable on viewport change
const mouseEventTimeouts = new Map();

const relatedTargets: { [type in MouseEnterOrMouseLeave]: HTMLElement | null } = {
    mouseenter: null, // the relatedTarget of the first occuring mouseenter event (the origin element where the users cursor navigated from)
    mouseleave: null, // the relatedTarget of the last occuring mouseleave event (the target element where the users cursor navigated to)
};

let hoverDelay = HOVER_DELAY;

const handleMouseEvent = (event: DOMEvent<MouseEvent, HTMLElement>) => {
    const { type, currentTarget } = event as { type: MouseEnterOrMouseLeave } & DOMEvent<MouseEvent, HTMLElement>;
    let { relatedTarget } = event;
    const timeouts = mouseEventTimeouts.get(currentTarget) || {
        mouseenter: null,
        mouseleave: null,
    };

    if ((type === 'mouseenter' && !relatedTargets[type]) || type === 'mouseleave') {
        relatedTargets[type] = relatedTarget;
    }

    // Prevent immediate opening of nav items when the user only traveled over them on his way to something else
    if (type === 'mouseleave' && timeouts.mouseenter) {
        const position = getRelatedPosition(getToElement(event)!, getFromElement(event)!);
        const isHorizontal = position === Node.DOCUMENT_POSITION_PRECEDING || position === Node.DOCUMENT_POSITION_FOLLOWING;

        if (isHorizontal) {
            // item was entered and left horizontally within the delay so disable the hover delay for the next item on the same level to prevent flickering
            hoverDelay = 0;
        }

        clearTimeout(timeouts.mouseenter);
        timeouts.mouseenter = null;
    }
    else if (type === 'mouseenter' && timeouts.mouseleave) {
        hoverDelay = HOVER_DELAY;
        clearTimeout(timeouts.mouseleave);
        timeouts.mouseleave = null;
    }
    else {
        if (type === 'mouseenter') {
            // substract 1ms from the hoverDelay for mouseenter events to reduce visual flickering
            // when the previous list is hidden and the new list is shown due to JS execution speed
            hoverDelay = Math.max(0, hoverDelay - 1);
        }

        mouseEventTimeouts.set(currentTarget, {
            [type]: setTimeout(() => {
                mouseEventTimeouts.set(currentTarget, {
                    [type]: null,
                });

                if (relatedTargets[type]) {
                    // In case of type 'mouseenter':
                    // if the last mouseenter event was cancelled we set the relatedTarget (the element where the cursor navigated from)
                    // of the next mouseenter event to the relatedTarget of the first mouseenter event.
                    // Like this the direction processing logic is not confused by the ignored event and
                    // treats it as a proper direction based on the first (not ignored) relatedTarget
                    // In case of type 'mouseleave':
                    // use the last registered relatedTarget element (the element where the cursor navigates to)
                    // as relatedTarget so the direction processing logic properly detects the direction when
                    // leaving the navigation horizontally
                    relatedTarget = relatedTargets[type]!;
                }

                relatedTargets.mouseenter = null;
                relatedTargets.mouseleave = null;

                // Fire custom event for other navigation services to listen to (but not in touch devices, no mouse there)
                !isTouchInput() && currentTarget.dispatchEvent(new CustomEvent(`nav:${type}`, {
                    detail: {
                        originalEvent: {
                            type,
                            currentTarget,
                            relatedTarget,
                        },
                    },
                }));
            }, hoverDelay),
        });

        hoverDelay = HOVER_DELAY;
    }
};

/**
 * This function is used to allow transitions from 0 to auto-height and vice-versa.
 * It adds transitionend and transitioncancel event listeners to the given
 * submenu element. When fired the event listeners are removed again from the submenu
 * so this function should be called each time before the the transition will be triggered
 * (i. e. submenu will be opened).
 * @param customPropertyName The name of the custom CSS property which is set
 * with the submenus scrollHeight on the submenu
 * @param submenu The submenu element to attach the transition listeners to
 * @param isOpen A function which should return true if the submenu is currently open, otherwise false.
 */
export const handleSubmenuMaxHeightTransitionEnd = (
    customPropertyName: string,
    submenu: HTMLElement,
    isOpen: () => boolean,
) => {
    submenu.style.setProperty(customPropertyName, `${submenu.scrollHeight}px`);

    if (isOpen()) {
        // submenu is already open and will be closed
        // so we don't need the transition event listeners
        return;
    }

    // submenu was opened
    const transitionEvents = ['transitionend', 'transitioncancel'] as const;

    const handleTransitionEnd = (e: TransitionEvent) => {
        if (e.target !== e.currentTarget || e.propertyName !== 'max-height') {
            // ignore bubbled up events or CSS properties we are not interested in
            return;
        }

        if (!isOpen()) {
            // ensure that the submenu was not closed again e. g. by clicking the toggle-button before the transition ended
            return;
        }

        // remove the custom property which will result in the submenu having a
        // max-height of 'none' = auto height
        submenu.style.removeProperty(customPropertyName);

        transitionEvents.forEach(event => {
            submenu.removeEventListener(event, handleTransitionEnd);
        });
    };

    transitionEvents.forEach(event => {
        submenu.addEventListener(event, handleTransitionEnd);
    });
};

export default initNavigation;
