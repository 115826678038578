import { skipToService } from '../helpers/skipToHelper';
import { setTabIndexes } from '../helpers/tabIndexHelper';
import { initMddAnimations } from '../helpers/mddAnimationHelper';
import {
    NAV_CLASSNAME,
    NAV_BURGER_CLASSNAME,
    NAV_BURGER_ACTIVE_CLASSNAME,
    NAV_ROOT_LEVEL_ID,
    NAV_MAIN_CONTAINER,
    NAV_LIST_LEVEL_1_CLASSNAME,
} from '../constants';
import {
    focusFirstElement,
    getAllSiblings,
    traverseToBody,
} from '../../../helpers/domHelper';
import initNavigation, {
    getNavItems,
    resetAllSubmenus,
} from '../helpers/navigationHelper';
import {
    isDesktopViewport,
    isMobileViewport,
} from '@vfde-brix/core';
import {
    disablePageScroll,
    enablePageScroll,
} from '../../../helpers/pageScroll';
import { useDispatch } from '../../../app/store';
import { setMddState } from '../slice';

/**
 * Init MDD
 */
export const mountMdd = (): CallableFunction[] => {
    const dispatch = useDispatch();

    const globalNavigation = document.getElementsByClassName(NAV_CLASSNAME)[0] as HTMLElement;
    const resizeHandlers: CallableFunction[] = [];

    if (!globalNavigation) {
        return resizeHandlers;
    }

    const mainNavContainer = globalNavigation.getElementsByClassName(NAV_MAIN_CONTAINER)[0] as HTMLElement;

    // init burger
    // TODO: give action instead of arrow function
    // TODO: attach/remove event per viewport change
    initBurger(globalNavigation, (isOpen: boolean) => {
        dispatch(setMddState(!isOpen));
    });

    // Close MDD whenever the viewport changes
    resizeHandlers.push(() => dispatch(setMddState(false)));

    const navItems = getNavItems();

    const keydownHandler = function (event: KeyboardEvent) {
        if (event.key !== 'Escape') {
            // not Escape, do nothing
            return;
        }

        if (isDesktopViewport()) {
            resetAllSubmenus(navItems);
        }
        else {
            const burger = getBurger(globalNavigation);

            if (burgerIsActive(burger)) {
                // if mobile menu is open, close it and focus burger
                dispatch(setMddState(false));
                burger.focus();
            }
        }
    };

    document.addEventListener('keydown', keydownHandler);

    // init click event listener to detect
    // when the user clicked outside the navigation
    // or the backdrop pseudo-element
    handleOutsideOrBackdropClick(mainNavContainer, () => {
        if (isDesktopViewport()) {
            // in desktop close all submenus
            resetAllSubmenus(navItems);
        }
        else {
            // in mobile dispatch the setMddState action
            dispatch(setMddState(false));
        }
    });

    // Initialize skip links
    skipToService(globalNavigation);

    // Set tab index
    resizeHandlers.push(setTabIndexes(globalNavigation));

    // animation
    resizeHandlers.push(initMddAnimations(globalNavigation));

    // Initialize MDD navigation functionality
    resizeHandlers.push(initNavigation(navItems));

    return resizeHandlers;
};

/**
 * Returns the burger element
 */
const getBurger = (context: Element | Document = document): HTMLElement =>
    context.getElementsByClassName(NAV_BURGER_CLASSNAME)[0] as HTMLElement;

/**
 * Initialize the burger
 * @param globalNavigation The globalNavigation element
 * @param toggleMddAction Callback function which is called
 * when the burger is clicked
 */
const initBurger = (globalNavigation: HTMLElement, toggleMddAction: CallableFunction) => {
    const burger = getBurger(globalNavigation);

    burger?.addEventListener('click', () => {
        const isOpen = burgerIsActive(burger);

        toggleMddAction(isOpen);
    });
};

/**
 * Initialize a global click event listener to detect
 * when the user clicked/touched outside the navigation
 * or on the backdrop pseudo-element in mobile
 * @param mainNavContainer The mainNavContainer element
 * @param callback Callback function which is called
 * when the user clicked/touched outside the navigation or
 * the backdrop pseudo-element.
 */
const handleOutsideOrBackdropClick = (mainNavContainer: HTMLElement, callback: CallableFunction) => {
    ['click', 'touchstart'].forEach(eventName => {
        window.addEventListener(eventName, e => {
            if (isDesktopViewport()) {
                if (e.type === 'click') {
                    // ignore click events in desktop viewport
                    // because for mouse users we already handle the mouseleave event on each list item
                    // and for touch users (tablets) we do the touchstart event

                    return;
                }
            }
            else if (e.type === 'touchstart') {
                // ignore touchstart events in mobile viewport
                // because for mobile users we already handle the click event

                return;
            }

            const target = e.target as HTMLElement;

            if (mainNavContainer.contains(target) && !target.classList.contains(NAV_LIST_LEVEL_1_CLASSNAME)) {
                // User clicked burger, nav-list or meta-nav but not the ::after backdrop directly
                return;
            }

            // At this point the user clicked outside the mainNavContainer
            // OR the user clicked the level 1 nav list inside of it.
            // Clicking the level 1 nav list so that it appears as `e.target` property is only possible
            // by clicking on the ::after pseudo-element which functions as a backdrop.

            callback();
        }, true);
    });
};

/**
 * Open/close the MDD programmatically (e.g. after skip link)
 */
export const toggleMdd = (focus = false, force?: boolean) => {
    const burger = getBurger();
    const open = typeof force === 'boolean' ? force : !burgerIsActive(burger);

    if (open) {
        disablePageScroll();

        if (isMobileViewport()) {
            traverseToBody(document.getElementsByClassName(NAV_CLASSNAME)[0] as HTMLElement, element => {
                const siblings = getAllSiblings(element);

                for (const sibling of siblings) {
                    sibling.setAttribute('inert', 'inert');
                }
            });
        }
    }
    else {
        enablePageScroll();
        traverseToBody(document.getElementsByClassName(NAV_CLASSNAME)[0] as HTMLElement, element => {
            const siblings = getAllSiblings(element);

            for (const sibling of siblings) {
                sibling.removeAttribute('inert');
            }
        });
    }

    burger.classList.toggle(NAV_BURGER_ACTIVE_CLASSNAME, open);
    burger.setAttribute('aria-expanded', open.toString());

    const ariaLabel = burger.getAttribute(`data-aria-label-${open ? 'close' : 'open'}`);
    ariaLabel && burger.setAttribute('aria-label', ariaLabel);

    if (open && focus) {
        focusFirstElement(`#${NAV_ROOT_LEVEL_ID}`);
    }
};

/**
 * Toggle nav item
 */
export const toggleMddNavItem = (navItems: HTMLCollectionOf<HTMLLIElement>) => {
    if (isDesktopViewport()) {
        return;
    }

    for (const navItem of navItems) {
        const submenu = navItem.getElementsByTagName('ul')[0];

        if (submenu) {
            navItem.querySelectorAll(':scope > button, :scope > a').forEach(trigger => {
                trigger.setAttribute('aria-expanded', 'true');
            });
        }
    }
};

/**
 * Checks if the burger is currently active
 * @param burger Burger element
 * @returns True if burger is active, otherwise false
 */
const burgerIsActive = (burger: Element) => burger.classList.contains(NAV_BURGER_ACTIVE_CLASSNAME);
