import {
    META_NAV_ITEM_CONTACT_BUTTON_CLASSNAME,
    META_NAV_ITEM_CONTACT_NUMBER_CLASSNAME,
} from './constants';
import './style.scss';
import mountContactNumber from './components/ContactNumber';
import mountContactButton from './components/ContactButton';
import beacon from '@vfde-sails/beacon';
import { useDispatch } from '../../app/store';
import {
    setInitialState,
    toggleFlyoutSuccess,
} from './slice';

const mountContactFeature = () => {
    const dispatch = useDispatch();

    beacon.subscribe('sails:flyout', msg => {
        switch (msg.type) {
            case 'TOGGLE_FLYOUT_SUCCESS':
                dispatch(toggleFlyoutSuccess(msg.payload));
                break;
            case 'SET_CONTACT_INFO':
                dispatch(setInitialState(msg.payload.contactInfo));
                break;
        }
    }, true);

    mountContactNumber(META_NAV_ITEM_CONTACT_NUMBER_CLASSNAME);
    mountContactButton(META_NAV_ITEM_CONTACT_BUTTON_CLASSNAME);
};

export default mountContactFeature;
