import {
    FOOTNOTE_ID_PREFIX,
    FOOTNOTE_LINK_CLASSNAME,
    Footnotes,
    createFootnotesComponent,
} from '@vfde-brix/footnotes';
import {
    CONTENT_ID,
    FOOTNOTES_CONTAINER_ID,
    ONLOAD_FOOTNOTE_SCROLL_DELAY,
} from '../constants';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';

let footnoteLinkCounter = 0;

/**
 * The ID prefix must not be written as 'footnote-link-'!
 * Otherwise it will match the :not() selector in initJumpLinkScrolling!
 */
const footnoteLinkIdPrefix = 'footnotelink-';
const createFootnoteLinkId = () => `${footnoteLinkIdPrefix}${footnoteLinkCounter++}`;

const footnotesExclusionSelectors = [
    'a', // ignore footnotes in links
    '.no-footnotes', // ignore footnotes in 'no-footnotes' class elements
    '.ws10-overlay', // ignore footnotes in ws10 overlays
    '.brix-overlay', // ignore footnotes in ws2 overlays
].join(', ');

/**
 * Initialize Footnotes
 */
export const initFootnotes = () => {
    const contentElement = document.getElementById(CONTENT_ID);
    const footnotesContainer = document.getElementById(FOOTNOTES_CONTAINER_ID);

    if (!contentElement || !footnotesContainer) {
        return;
    }

    // mount the footnotes component
    const footnotes = createFootnotesComponent(footnotesContainer, NO_PATTERN_BUSINESS_LOGIC);

    connectFootnotes([contentElement]);

    addClickListener((targetFootnoteNumber, footnoteLinkId) => {
        const footnoteLinkAriaLabel = window.globalPageOptions?.footnotes?.backLinkLabel
            .replace(
                '{footnoteNumber}',
                targetFootnoteNumber.toString(),
            )
        ;

        footnotes.scrollToFootnote(
            targetFootnoteNumber,
            footnoteLinkId,
            footnoteLinkAriaLabel,
        );
    });

    observeDom(contentElement, targetElements => {
        connectFootnotes(targetElements);
    });

    scrollToFootnoteFromLocationHash(targetFootnoteNumber => {
        setTimeout(() => {
            footnotes.scrollToFootnote(targetFootnoteNumber);
        }, ONLOAD_FOOTNOTE_SCROLL_DELAY);
    });

    // make footnotes available in the window so apps could update them if needed
    window.vf = window.vf || {};
    window.vf.sails = window.vf.sails || {};
    window.vf.sails.footnotes = footnotes;
};

/**
 * Searches for sup elements in the given contextElements and wraps them into an anchor referencing the footnote ID
 * @param contextElements Array of elements
 */
const connectFootnotes = (contextElements: HTMLElement[]) => {
    // only get those sups which are not nested in an anchor already
    const sups = contextElements.flatMap(contextElement => Array.from(contextElement.querySelectorAll<HTMLElement>('sup')));

    for (const sup of sups) {
        const supNumber = parseInt(sup.textContent!.trim(), 10);

        /* istanbul ignore if */
        if (Number.isNaN(supNumber)) {
            // this sup does not seem to be a footnote so we ignore it
            continue;
        }

        const shouldIgnoreFootnote = !!sup.closest(footnotesExclusionSelectors);

        /* istanbul ignore if */
        if (shouldIgnoreFootnote) {
            // Ignore footnotes that are nested within certain other elements.
            // For example we don't support clicking footnotes in overlays for now.
            // If its required, it needs to be re-evaluated in future.
            continue;
        }

        const anchor = document.createElement('a');

        for (const attribute of sup.attributes) {
            // move all attributes from sup to anchor
            anchor.setAttribute(attribute.name, attribute.value);
            sup.removeAttribute(attribute.name);
        }

        /* istanbul ignore else */
        if (anchor.classList.length === 0) {
            // if the sup had no class, add the default footnote-link class
            anchor.classList.add(FOOTNOTE_LINK_CLASSNAME);
        }

        const footnoteLinkId = createFootnoteLinkId();

        anchor.id = footnoteLinkId;
        anchor.setAttribute('href', `#${Footnotes.getFootnoteId(supNumber)}`);

        /* istanbul ignore else */
        if (window.globalPageOptions?.footnotes?.linkLabel) {
            anchor.setAttribute(
                'aria-label',
                window.globalPageOptions.footnotes.linkLabel
                    .replace('{footnoteNumber}', supNumber.toString())
                    .replace('{footnote}', supNumber.toString()), // the 'footnote' placeholder is deprecated and can be removed in future
            );
        }

        // wrap sup in anchor
        sup.parentElement!.insertBefore(anchor, sup);
        anchor.appendChild(sup);
    }
};

/**
 * Observes the DOM for added nodes to automatically connect new footnotes.
 * @param contextElement The element to observe
 * @param callback The callback function which is called when nodes were added to the DOM.
 */
const observeDom = (contextElement: HTMLElement, callback: (targetElements: HTMLElement[]) => void) => {
    const observerConfig = { attributes: false, childList: true, subtree: true };

    const handleDomMutation = (records: MutationRecord[]) => {
        const hasAddedNodes = records.some(record => record.type === 'childList' && record.addedNodes.length);

        /* istanbul ignore if */
        if (!hasAddedNodes) {
            // if no nodes were added, there can't be new footnotes
            return;
        }

        // get target elements from records, avoiding duplicates
        const targetElements = [...new Set(records.map(record => record.target))] as HTMLElement[];

        callback(targetElements);
    };

    const observer = new MutationObserver(handleDomMutation);

    // observe the whole content area for new injected sups
    observer.observe(contextElement, observerConfig);
};

/**
 * Adds the click listener to the document and handles the clicks by checking if a valid footnote was clicked.
 * @param callback The callback function to be called when a valid footnote was clicked.
 */
const addClickListener = (callback: (targetFootnoteNumber: number, footnoteLinkId: string) => void) => {
    document.addEventListener('click', e => {
        const target = e.target as HTMLAnchorElement;
        const closestFootnoteLink = target.closest(`a[href^="#${FOOTNOTE_ID_PREFIX}"]`);

        if (!closestFootnoteLink) {
            // click was not on a footnote-link
            return;
        }

        const targetFootnoteNumber = +closestFootnoteLink.getAttribute('href')!.trim().slice(1).replace(FOOTNOTE_ID_PREFIX, '');

        /* istanbul ignore if */
        if (Number.isNaN(targetFootnoteNumber)) {
            return;
        }

        e.preventDefault();

        callback(targetFootnoteNumber, closestFootnoteLink.id);
    });
};

/**
 * Checks the location hash (fragment) if it contains a footnote reference.
 * If yes and the footnote is found, it will scroll to the referenced footnote.
 * @param callback The callback function which is called with the footnote number
 * if the URL fragment contains a valid value.
 */
const scrollToFootnoteFromLocationHash = (callback: (targetFootnoteNumber: number) => void) => {
    const trimmedHash = window.location.hash.trim();

    if (!trimmedHash) {
        // no hash given
        return;
    }

    const pattern = new RegExp(`${FOOTNOTE_ID_PREFIX}(\\d+)`);
    const matches = trimmedHash.match(pattern);

    if (!matches?.length) {
        // hash is given but contains no footnote id
        return;
    }

    const footnoteNumber = parseInt(matches[1], 10);
    callback(footnoteNumber);
};
